<template>
  <module-container
    :title="$t('received.invoices')"
    dense
    hide-new-button
    route-name="invoices.received"
    show-toolbar
  >
    <template #route>
      <invoices-list
        :movement-type="[]"
        :hide-headers="['status_dgi']"
        filter-key="received"
        base-route="invoices.received"
        update-route="invoices.received.view"
        signed
      />
    </template>

    <template #filters>
      <invoice-filters filter-key="received_unprocessed" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesList from "@/modules/invoices/components/InvoicesList.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";

@Component({
  components: { InvoiceFilters, ActiveIcon, InvoicesList },
})
export default class ReceivedList extends Mixins(PaginateProviderMixin) {}
</script>
